import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UserDetail } from '@core/models/interfaces/user';
import { UserService } from '@core/services/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { CheckboxModule } from 'primeng/checkbox';
import { FloatLabelModule } from 'primeng/floatlabel';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayModule } from 'primeng/overlay';

@Component({
  selector: 'app-user-select-dropdown',
  templateUrl: './user-select-dropdown.component.html',
  styleUrl: './user-select-dropdown.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    InputTextModule,
    OverlayModule,
    InfiniteScrollDirective,
    IconFieldModule,
    InputIconModule,
    FloatLabelModule,
    CheckboxModule,
    CustomAvatarComponent,
    SearchInputComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class UserSelectDropdownComponent implements OnInit, OnChanges {
  @Input() appendTo: 'body' | HTMLElement | undefined;
  @Input() isHighlightName: boolean = false;
  @Input() isApplyBtn = false;
  @Input() userSelected?: UserDetail;
  @Input() totalUsers: number = 0;
  @Input() users: UserDetail[] = [];
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() placeholder: string = '';
  @Input() isBorder: boolean = false;
  @Input() isFetchUsersPermission: boolean = false;

  @Output() userSelectedChange = new EventEmitter<UserDetail>();
  @Output() onApply = new EventEmitter<UserDetail>();

  tempSelected?: UserDetail;
  keyword = '';
  page = 0;
  visible = false;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    if (this.userSelected) {
      this.tempSelected = { ...this.userSelected };
    }
  }

  ngOnChanges() {
    if (this.userSelected) {
      this.tempSelected = { ...this.userSelected };
    } else {
      this.tempSelected = undefined;
    }
  }

  onScroll() {
    if (this.totalUsers > this.users.length) {
      this.page++;
      this.fetchUsers('SCROLL');
    }
  }

  onSelectLead(user: UserDetail) {
    this.tempSelected = user;
    if (!this.isApplyBtn) {
      this.userSelected = user;
      this.userSelectedChange.emit(this.userSelected);
      this.visible = false;
    }
  }

  fetchUsers(type: 'SCROLL' | 'SEARCH') {
    const params = {
      page: this.page,
      keyword: this.keyword
    };
    const source = this.isFetchUsersPermission
      ? this.userService.getUsersPermission(params)
      : this.userService.getUsers(params);

    source.subscribe({
      next: res => {
        this.totalUsers = res.data.total;
        if (type === 'SCROLL') {
          this.users = [...this.users, ...res.data.content];
        }
        if (type === 'SEARCH') {
          this.users = res.data.content;
        }
      }
    });
  }

  onSearch(keyword: string) {
    this.keyword = keyword;
    this.page = 0;
    this.fetchUsers('SEARCH');
  }

  onHide() {
    if (this.userSelected) {
      this.tempSelected = { ...this.userSelected };
    }
  }

  onApplyClick() {
    this.visible = false;

    if (this.tempSelected) {
      this.userSelected = { ...this.tempSelected };
      this.userSelectedChange.emit(this.userSelected);
      this.onApply.emit(this.userSelected);
    }
  }

  onToggle() {
    if (!this.disabled) {
      this.visible = !this.visible;
    }
  }
}
